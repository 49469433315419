import banner from "../images/panthers_banner5.png";
import { Posts } from '../components/posts';
import { SideSection } from '../components/sidesection';
import herrenPhoto from "../images/herren-photo.png"
import { Probetraining } from "../components/probetraining";
import { Team } from "./team";

export const Herren = () => {
  const beschreibung1 = "Für Männer ab 18 Jahren."
  const beschreibung2 = "Wenn du Lust hast im Team Basketball zu spielen und Spaß zu haben, dann komme gerne vorbei. Die Herren konnten sich in der Saison 21/22 den Meisterschaftstitel holen und sicherten sich somit den Aufstieg. Die Mannschaft nimmt auch in der kommenden Saison 22/23 am Spielbetrieb teil."
    return (
      <div>
        <Team
        teamName="Herren"
        photo={herrenPhoto}
        description1={beschreibung1}
        description2={beschreibung2}
        tableTeam="herren"
        tableAge="Ab 18 Jahren"
        tableTime="Mi: 20:00 - 21:30, Fr: 18:00 - 19:30"
        tableLocation="Heinrich-Vogler-Halle"
        tableTrainer="Martin Wegener"
        />
      </div>

      )
}