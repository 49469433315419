/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import logo from '../images/panthers_logo.png';
import { Popover, Transition } from '@headlessui/react'
import {
  BookmarkAltIcon,
  CalendarIcon,
  ChartBarIcon,
  CursorClickIcon,
  MenuIcon,
  PhoneIcon,
  PlayIcon,
  RefreshIcon,
  ShieldCheckIcon,
  SupportIcon,
  ViewGridIcon,
  XIcon,
  StarIcon
} from '@heroicons/react/outline'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom';

const year = new Date().getFullYear()
const teams = [
{
    name: 'U10',
    description: 'Jahrgänge: ' + (year - 8) + " und " + (year - 9),
    href: '/u10',
  },
  {
    name: 'U12',
    description: 'Jahrgänge: ' + (year - 10) + " und " + (year - 11),
    href: '/u12',
  },
  {
    name: 'U14',
    description: 'Jahrgänge: ' + (year - 12) + " und " + (year - 13),
    href: '/u14',
  },
  { name: 'U16',
    description: 'Jahrgänge: ' + (year - 14) + " und " + (year - 15),
    href: '/u16', 
  },
  {
    name: 'U18',
    description: 'Jahrgänge: ' + (year - 16) + " und " + (year - 17),
    href: '/u18',
  },
  {
    name: 'Herren',
    description: 'Jahrgänge: unter ' + (year - 18),
    href: '/herren',
  },  {
    name: 'Ü35',
    description: 'Jahrgänge: unter ' + (year - 35),
    href: '/u35',
  },
]
const callsToAction = [
  { name: 'Probetraining machen', href: '/probetraining', icon: StarIcon },
]
const info = [
  {
    name: 'Neuigkeiten',
    href: '/news',
  },
  {
    name: 'Trainingszeiten',
    href: '/trainingszeiten',
  },
  {
    name: 'Verein',
    href: '/verein',
  },
{
    name: 'Fanshop',
    href: '/fanshop',
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Navbar() {
  return (
    <Popover className="bg-black relative ">
      <div className="bg-black max-w-7xl mx-auto px-4 sm:px-6">
        <div className="bg-black flex justify-between items-center  py-6 md:justify-start md:space-x-10 lg:h-24">
          <div className="bg-black flex justify-start lg:w-100px lg:flex-none ">
            <Link to="/">
              <img
                className="object-scale-down lg:h-32 lg:w-20 h-10"
                src={logo}
                alt=""
              />
            </Link>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden md:flex space-x-10 lg:flex-1 ">
            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? 'text-gray-900' : 'text-gray-500',
                      'group bg-black rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    )}
                  >
                    <span className="text-white">Teams</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? 'text-white' : 'text-white',
                        'ml-2 h-5 w-5 group-hover:text-gray-500'
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-20 lg:left-1/2 lg:-translate-x-1/2">
                      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                          {teams.map((item) => (
                            <div
                              className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                            >
                              <Link to={item.href} className="text-base font-medium text-gray-900" >
                                {item.name}
                                <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                                </Link>
                                <div className="ml-4">
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                          {callsToAction.map((item) => (
                            <div key={item.name} className="flow-root">
                              <Link to={item.href} 
                                className="-m-3 p-3 bg-blue-600 flex items-center rounded-md text-base font-medium text-white hover:bg-blue-800 hover:text-white"
                              >
                                <item.icon className="flex-shrink-0 h-6 w-6 text-white " aria-hidden="true" />
                                {item.name}
                              </Link>
                            </div>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
            <Link to="/news" className="text-base font-medium text-white rounded-md px-2 hover:bg-white hover:text-black" >
              News
            </Link>
            <Link to="/trainingszeiten" className="text-base font-medium rounded-md px-2 text-white hover:bg-white hover:text-black" >
              Trainingszeiten
            </Link>
            <Link to="/verein" className="text-base font-medium rounded-md text-white px-2 hover:bg-white hover:text-black" >
              Verein
            </Link>
<Link to="/fanshop" className="text-base font-medium rounded-md text-white px-2 hover:bg-white hover:text-black" >
              Fanshop
            </Link>
          </Popover.Group>

          {/* <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
            <a href="#" className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900">
              Sign in
            </a>
            <a
              href="#"
              className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
            >
              Sign up
            </a>
          </div> */}
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel focus className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>
                  <Link to="/">
<img
                    className="h-12 w-auto"
                    src={logo}
                    alt="Workflow"
                  />

                  </Link>
                                  </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-8">
                  {info.map((item) => (
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">

                    <Link 
                    className="ml-3 text-base font-medium text-gray-900"
                    to={item.href}>
                    {item.name}
                    </Link>
                  </Popover.Button>
                   
                  ))}
                </nav>
              </div>
            </div>
            <div className="py-6 px-5 space-y-6">
                <p className="flex justify-center items-center font-extrabold text-xl">Teams</p>
              <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                {teams.map((item) => (
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <Link
                    to={item.href}
                    className="text-base font-medium text-gray-900 hover:text-gray-700"
                  >
                    {item.name}
                  </Link>
                  </Popover.Button>
                ))}
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}
