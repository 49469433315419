import u12photo from "../images/u12-photo.png"
import { Team } from "./team";

export const U12 = () => {
  const beschreibung1 = "Für Mädchen und Jungs aus dem Jahrgang 2013 und 2014."
  const beschreibung2 = "Wenn du Lust hast im Team Basketball zu spielen und Spaß zu haben, dann komme gerne für ein Probetraining vorbei. Während jeder Saison wird zusätzlich zu den Trainings auch an mehreren Turnieren teilgenommen."

    return (
      <div>
        <Team
        teamName="U12 männlich/weiblich"
        photo={u12photo}
        description1={beschreibung1}
        description2={beschreibung2}
        tableTeam="U10 m/w"
        tableAge="8 - 9 Jahre"
        tableTime="Di: 16:15 - 17:45, Fr: 16:30 - 18:00"
        tableLocation="Heinrich-Vogler-Halle"
        tableTrainer="Oskar Söhl"
        />
      </div>

      )
}