import banner from "../images/panthers_banner5.png";
import { Posts } from '../components/posts';
import { SideSection } from '../components/sidesection';
import u16photo from "../images/u16-photo.png"
import { Probetraining } from "../components/probetraining";
import { Team } from "./team";

export const U16 = () => {
  const beschreibung1 = "Für Jungs aus dem Jahrgang 2007 und 2008."
  const beschreibung2 = "Wenn du Lust hast im Team Basketball zu spielen und Spaß zu haben, dann komme gerne vorbei. Die U16 konnte sich in der Saison 21/22 den Meisterschaftstitel holen. Sie nimmt auch in der kommenden Saison 22/23 am Spielbetrieb teil."
    return (
      <div>
        <Team
        teamName="U16 männlich"
        photo={u16photo}
        description1={beschreibung1}
        description2={beschreibung2}
        tableAge="2007 - 2008"
        tableTeam="u16"
        tableTime="Di: 18:30 - 20:00, Fr: 18:00 - 19:30"
        tableLocation="Heinrich-Vogler-Halle"
        tableTrainer="Hannes Knoph"
        />
      </div>

      )
}