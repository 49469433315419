import banner from "../images/panthers_banner5.png";
import { Posts } from '../components/posts';
import { SideSection } from '../components/sidesection';
import u14photo from "../images/u14-photo.png"
import { Probetraining } from "../components/probetraining";
import { Team } from "./team";

export const U14 = () => {
  const beschreibung1 = "Für Mädchen und Jungs aus dem Jahrgang 2009 und 2010."
  const beschreibung2 = "Wenn du Lust hast im Team Basketball zu spielen und Spaß zu haben, dann komme gerne vorbei. Die U14 nimmt zum ersten Mal am Spielbetrieb 22/23 teil."
    return (
      <div>
        <Team
        teamName="U14 männlich"
        photo={u14photo}
        description1={beschreibung1}
        description2={beschreibung2}
        tableAge="2009 - 2010"
        tableTeam="u14"
        tableTime="Mo: 16:30 - 18:00 Fr: 18:00 - 19:30"
        tableLocation="Heinrich-Vogler-Halle"
        tableTrainer="Oskar Söhl/Hannes Knoph"
        />
      </div>

      )
}