import React from "react";
import logo from "../images/fanshop_image.png";
import { ProbetrainingWidget } from "./probetraining";
import diwium from "../images/sponsor4.png"
import sponsor2 from "../images/sponsor2.png"
import Instagram from "../images/instagram-image.jpg"
import { HeadText } from "./headText";

export const SideSection = () => {
    return (
        <div className="sidesection">
            <div className="fanshop">
                <div className=" my-8 md:my-4">
                <HeadText text="Fanshop"/>
                </div>
                <div className="hover:scale-110 transition duration-300 overflow-hidden shadow-lg rounded-lg hover:bg-blend-darken ">
                    <a href="https://shop.sport-basti.de/produkte/vereinsshops/vfl-oldesloe-basketball/">

                <img src={logo} className=" p-4 ml-20 md:ml-0  w-8/12  md:w-full "></img>
                    </a>
                </div>
            </div>
            <div>
                <HeadText text="Sponsoren"/>
            <div className="grid grid-cols-1 gap-1">
                <a href="https://diwium.de/start.html">
            <img src={diwium}className="max-h-40 lg:w-full flex justify-center items-center  object-fill rounded-lg" />
                </a>
            <img src={sponsor2}className="max-h-40 lg:w-1/2 object-fill rounded-lg" />
            </div>
            <div >
                <p className="text-3xl font-bold text-center mt-8">Folg uns auf Instagram</p>
                <div className="flex justify-center hover:scale-110 transition duration-300 ">
                    <a href="https://www.instagram.com/panthers.oldesloe/">
                <img src={Instagram} className="w-28 mt-4"/>
                    </a>

                </div>
            </div>



            </div>
        </div>
    )
}