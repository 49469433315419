import { useParams } from "react-router-dom"
import { blogPosts, initialPosts } from "../components/posts";

export const Post = (props) => {
    const {id} = useParams();
    const posts = initialPosts.find(el => el.id == id)

    return (
        <div>
            <div className="md:flex justify-center items-center   m-8 ">
                <span className="mr-40 w-full">
            <h1 className=" text-3xl sm:text-5xl  font-black">{posts.title}</h1>
            <h1 className="mt-4">{posts.date}</h1>
                </span>
                <span className="flex justify-center items-center sm:flex-auto w-full h-full">
            <img src={posts.image} className="rounded-lg object-scale-down h-96  w-full  "/>
                </span>
            </div>
            <div class="divider px-40"></div> 
            <div className="text-lg p-8 lg:mx-60 lg:my-20 bg-gray-50 rounded-lg">
            <h1>{posts.text}</h1>
            </div>
        </div>
    )
}