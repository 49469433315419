import banner from "../images/panthers_banner5.png";
import { Posts } from '../components/posts';
import { SideSection } from '../components/sidesection';


export const Home = () => {
    return (
        <div>

     <div className="md:flex">
       <Posts home={true}/>      
      <div className="bg-green md:w-4/12 sm:w-full border-l-8 border-grey-100 lg:px-16 px-4 ">
        <SideSection/>
      </div>
 

      </div>
        </div>
    )
}