import placeholder1 from "../images/placeholder1-min.jpg"


export const PostPreview = (props) => {

    return(
<div class="group hover:scale-110 transition duration-300 overflow-hidden shadow-lg rounded-lg h-90 w-80 md:w-100 cursor-pointer m-auto">
    <a href={props.href} class="w-full block h-full">
        <img alt="blog photo" src={props.image} class="max-h-40 w-full object-cover"/>
        <div class="group-hover:bg-black bg-white dark:bg-gray-800 w-full p-4 hover:">
            <p class="group-hover:text-white text-gray-800 dark:text-white text-xl font-semibold mb-2 ">
                {props.title}
            </p>
            <p class=" group-hover:text-white text-gray-400 dark:text-gray-300 font-regular text-md ">
                {props.text.slice(0, 100)}...
            </p>
        </div>
    </a>
</div>
    )
}