import { Link } from "react-router-dom"
import { Header } from "../components/header"

export const Trainingszeiten = () => {
    return(
        <div className="mb-12">
          <Header text="Trainingszeiten"/>

            <div class="flex flex-col  ">
  <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
    <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
      <div class="overflow-x-auto">
        <table class="min-w-3/4 ml-12">
          <thead class="border-b">
            <tr>
              <th scope="col" class="text-lg font-bold text-gray-900 px-6 py-4 text-left">
                Mannschaft
              </th>
              <th scope="col" class="text-lg font-bold text-gray-900 px-6 py-4 text-left">
                Alter
              </th>
              <th scope="col" class="text-lg font-bold text-gray-900 px-6 py-4 text-left">
                Zeit
              </th>
              <th scope="col" class="text-lg font-bold text-gray-900 px-6 py-4 text-left">
                Ort
              </th>
              <th scope="col" class="text-lg font-bold text-gray-900 px-6 py-4 text-left">
                Trainer
              </th>
                        </tr>
          </thead>
          <tbody>
            <tr class="border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm  text-gray-900">U10 m/w</td>
              <td class="text-sm text-gray-900 px-6 py-4 whitespace-nowrap">
                8 - 9 Jahre
              </td>

              <td class="text-sm text-gray-900 px-6 py-4 whitespace-nowrap">
                Fr: 16:30 - 18:00
              </td>
              <td class="text-sm text-gray-900 px-6 py-4 whitespace-nowrap">
                Heinrich-Vogler-Halle
              </td>
              <td class="text-sm text-gray-900 px-6 py-4 whitespace-nowrap">
                Corina Pochanke
              </td>
            </tr>
            <tr class="bg-white border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">U12 m/w</td>
              <td class="text-sm text-gray-900 px-6 py-4 whitespace-nowrap">
                10 - 11 Jahre
              </td>
              <td class="text-sm text-gray-900 px-6 py-4 whitespace-nowrap">
                Di: 16:15 - 17:45, Fr: 16:30 - 18:00
              </td>
              <td class="text-sm text-gray-900 px-6 py-4 whitespace-nowrap">
                Heinrich-Vogler-Halle
              </td>
              <td class="text-sm text-gray-900 px-6 py-4 whitespace-nowrap">
                Oskar Söhl
              </td>
            </tr>
            <tr class="bg-white border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">U14 männlich</td>
              <td class="text-sm text-gray-900 px-6 py-4 whitespace-nowrap">
                12 - 13 Jahre
              </td>
              <td class="text-sm text-gray-900 px-6 py-4 whitespace-nowrap">
                Mo: 16:30 - 18:00, Fr: 18:00 - 19:30 
              </td>
              <td class="text-sm text-gray-900 px-6 py-4 whitespace-nowrap">
                Heinrich-Vogler-Halle
              </td>
              <td class="text-sm text-gray-900 px-6 py-4 whitespace-nowrap">
                Oskar Söhl/Hannes Knoph
              </td>
            </tr>
            <tr class="bg-white border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">U14 weiblich</td>
              <td class="text-sm text-gray-900 px-6 py-4 whitespace-nowrap">
                12 - 13 Jahre
              </td>
              <td class="text-sm text-gray-900 px-6 py-4 whitespace-nowrap">
                Di: 17:45 - 19:00 ,Fr: 16:30 - 18:00
              </td>
              <td class="text-sm text-gray-900 px-6 py-4 whitespace-nowrap">
                Heinrich-Vogler-Halle
              </td>
              <td class="text-sm text-gray-900 px-6 py-4 whitespace-nowrap">
                Laura Andresen
              </td>
            </tr>
            <tr class="bg-white border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm  text-gray-900">U16 männlich</td>
              <td class="text-sm text-gray-900 px-6 py-4 whitespace-nowrap">
                14 - 15 Jahre
              </td>
              <td class="text-sm text-gray-900 px-6 py-4 whitespace-nowrap">
                Di: 18:30 - 20:00, Fr: 18:00 - 19:30
              </td>
              <td class="text-sm text-gray-900 px-6 py-4 whitespace-nowrap">
                Heinrich-Vogler-Halle
              </td>
              <td class="text-sm text-gray-900 px-6 py-4 whitespace-nowrap">
                Hannes Knoph
              </td>
            </tr>
            <tr class="bg-white border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">U18 männlich</td>
              <td class="text-sm text-gray-900 px-6 py-4 whitespace-nowrap">
                16 - 17 Jahre
              </td>
              <td class="text-sm text-gray-900 px-6 py-4 whitespace-nowrap">
                Di: 18:30 - 20:00, Fr: 19:30 - 21:00
              </td>
              <td class="text-sm text-gray-900 px-6 py-4 whitespace-nowrap">
                Heinrich-Vogler-Halle
              </td>
              <td class="text-sm text-gray-900  px-6 py-4 whitespace-nowrap">
                Chams Abressi Toure
              </td>
            </tr>
            <tr class="bg-white border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm  text-gray-900">Herren</td>
              <td class="text-sm text-gray-900  px-6 py-4 whitespace-nowrap">
                ab 18 Jahre
              </td>
              <td class="text-sm text-gray-900  px-6 py-4 whitespace-nowrap">
                Mi: 20:00 - 21:30, Fr: 18:00 - 19:30
              </td>
              <td class="text-sm text-gray-900 px-6 py-4 whitespace-nowrap">
                Heinrich-Vogler-Halle
              </td>
              <td class="text-sm text-gray-900 px-6 py-4 whitespace-nowrap">
                Martin Wegener
              </td>
            </tr>
            <tr class="bg-white border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm  text-gray-900">Ü35 m/w</td>
              <td class="text-sm text-gray-900 px-6 py-4 whitespace-nowrap">
                ab 35 Jahre
              </td>
              <td class="text-sm text-gray-900 px-6 py-4 whitespace-nowrap">
                Di: 20:00 - 21:30
              </td>
              <td class="text-sm text-gray-900 px-6 py-4 whitespace-nowrap">
                Heinrich-Vogler-Halle
              </td>
              <td class="text-sm text-gray-900 px-6 py-4 whitespace-nowrap">
                Jörg Söhl
              </td>
            </tr>
                      </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
        </div>
    )
}