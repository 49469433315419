import { HeadText } from "../components/headText";
import { ProbetrainingWidget } from "../components/probetraining";
import { StatsTable } from "../components/table";

export const Team = props => {
    return (
    <div className="mx-4 lg:ml-12 mb-20">
<p class="text-4xl font-bold text-gray-800 my-12">{props.teamName}</p>
      <div className="lg:flex">
        <div>
<img className="w-11/12 rounded-3xl  object-scale-down  " src={props.photo}/>
      <h1 className="text-2xl font-medium my-4 lg:mt-6  lg:text-4xl " >Beschreibung</h1>
            <p className="my-2 lg:text-xl break-words">
                {props.description1}
                <br/>
                {props.description2}
              </p>

        </div>


<div className="lg:ml-8 lg:w-1/2 lg:-mt-24">
  <HeadText text="Tabelle"/>
  <StatsTable team={props.tableTeam}/>
  <HeadText text="Trainingszeiten"/>
          <div className="overflow-x-auto ">
  <table className="table table-zebra w-full ">
    <thead>
      <tr>
        <th>Alter</th>
        <th>Zeit</th>
        <th>Ort</th>
        <th>Trainer</th>
      </tr>
    </thead>
    <tbody>
<tr>
        <td className="text-sm">{props.tableAge}</td>
        <td className="text-sm">{props.tableTime}</td>
        <td className="text-sm">{props.tableLocation}</td>
        <td className="text-sm">{props.tableTrainer}</td>
      </tr>
    </tbody>
  </table>
</div>

     


</div>

      </div>
       <div class="flex flex-col mb-12">
              <div className="mt-12">
      <h1 className="text-center text-3xl font-semibold mt-4 text-white p-4 bg-black rounded-xl lg:w-1/2">Probetraining</h1>
      <p className="text-xl my-8">Du hast Lust im Team Basketball zu spielen? 
      <br/>
      Dann melde dich hier unverbindlich für ein Probetraining
        bei uns an.
      </p>
  </div>
<label for="my-modal-3" class="btn btn-success text-black font-bold lg:text-lg w-80">Probetraining vereinbaren</label>

<input type="checkbox" id="my-modal-3" class="modal-toggle" />
<div class="modal">
  <div class="modal-box relative">
    <ProbetrainingWidget />
    <label for="my-modal-3" class="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
  </div>
</div>
</div>
    </div>
      )
}