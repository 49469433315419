import banner from "../images/panthers_banner5.png";
import { Posts } from '../components/posts';
import { SideSection } from '../components/sidesection';
import u18photo from "../images/u18-photo.png"
import { Probetraining } from "../components/probetraining";
import { Team } from "./team";
import { StatsTable } from "../components/table";

export const U18 = () => {
  const beschreibung1 = "Für Jungs aus dem Jahrgang 2003 und 2004."
  const beschreibung2 = "Wenn du Lust hast im Team Basketball zu spielen und Spaß zu haben, dann komme gerne vorbei. Die U18 nimmt auch in der kommenden Saison 22/23 wieder am Spielbetrieb teil."
    return (
      <div>
        <Team
        teamName="U18 männlich"
        photo={u18photo}
        description1={beschreibung1}
        description2={beschreibung2}
        tableTeam="u18"
        tableAge="2003 - 2004"
        tableTime="Di: 18:30 - 20:00, Fr: 19:30 - 21:00"
        tableLocation="Heinrich-Vogler-Halle"
        tableTrainer="Chams Toure"
        />
      </div>

      )
}