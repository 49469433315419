import TrainerGesucht from "../images/trainer_post.png"
import U18Vize from "../images/u18Vize.png"
import HerrenMeister from "../images/herrenMeister.png"
import SiegEsingen from "../images/siegEsingen.png"
import U16Meister from "../images/u16_meister.png"
import { PostPreview } from "./postPreview"
import { Link } from "react-router-dom"
import { useEffect, useState } from "react"
import PanthersLogo from "../images/panthers_logo.png"
import Sieg1Pokal from "../images/sieg1-pokal.JPG"


    const sampleText = "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.   Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet,"
const text1 = "Eine weitere Saison der Panthers steht an. Diesmal schließt sich die U14 an und startet in der kommenden Saison 22/23 in der Landesliga. Somit nehmen insgesamt 4 Mannschaften der Panther am Spielbetrieb teil (U14, U16, U18, Herren). \nDurch intensives Training, Anfängerturnieren und Freundschaftsspielen ist der Kader der U14 nun bereit ihre erste Saison in der Landesliga starten zu können. \nIn der letzten Saison hatten die Panthers viele Erfolge. Die U16 und Herrenmannschaft konnten sich jeweils den ersten Platz in ihrer Liga sichern. Dadurch stiegen die Herren eine Liga auf und können sich in der kommenden Saison weiteren Herausforderungen stellen. \nMit reichlich Neuzugängen in allen Teams, einem neuen Trainer und einer guten Saisonvorbereitung hoffen wir auf eine erfolgreiche Saison der Oldesloer Panthers"
const text2 = "Am 22.10.2022 hatte die Herrenmannschaft ihr erstes Heim- und Pokalspiel in der Heinrich-Vogler-Halle gegen den Kieler TB 2. und fuhren den ersten Sieg der Saison ein. \nDie Halle war mit vielen Zuschauern besetzt, die für Laune und Energie über das gesamte Spiel gesorgt haben. \nVon Beginn an, war es bis zur Halbzeit, ein sehr ausgeglichenes Spiel für beide Teams. Doch das änderte sich im dritten Viertel. Durch einer hervorragenden aggressiven Defense der Panthers und einer sehr guten Offensive führte diese Spielweise schnell zu einem 9:0 Run. So konnten sich die Panthers eine starke Führung von 12 Punkten erarbeiten. Im letzten Viertel, fiel den Panthers der Einstieg schwer mit vielen Fouls und einer starken Offense seitens der Gegner, wodurch die Kieler den Vorsprung auf 5 Punkte verkürzen konnten. Zum Ende hin konnten sich die Panthers nochmal aufrichten und gewannen somit ihr erstes Pokalspiel mit 62:57 Punkten. Es war eine super Teamleistung von Anfang bis Ende und der enthusiastische Coach Martin Wegener äußerte sich den Spielern gegenüber mit viel Lob. Somit geht es in die nächste Runde. Die Spielinfos könnt ihr entweder zeitnah auf unserer Website oder Instagram-Seite einsehen."

export const initialPosts = [

     {
        "id":2,
        "title": "Sieg im ersten Pokalspiel gegen den Kieler TB 2",
        "text": text2,
        "image": Sieg1Pokal,
        "date": "22.10.2022"
    },   {
  "id": 1,
            "title": "Anstehende Saison für die Panthers",
            "text": text1,
            "image": PanthersLogo,
            "date": "21.05.2022"
    },

]


export const blogPosts = 
    [
        {
            "id": 1,
            "title": "Gesucht: Herren Coach",
            "body": sampleText,
            "image": TrainerGesucht,
            "date": "21.05.2022"
        },

        {
            "id": 2,
            "title":"U16 Meister",
            "body": sampleText,
            "image": U16Meister ,
            "date": "21.05.2022"
        },

        {
            "id": 3,
            "title": "U18 Vize-Pokalsieger",
            "body": sampleText,
            "image": U18Vize,
            "date": "21.05.2022"
        },
         {
            "id": 4,
            "title": "Herren Meister und Aufstieg",
            "body": sampleText,
            "image": HerrenMeister,
            "date": "21.05.2022"
        },       
        {
            "id": 5,
            "title": "Spannender Sieg gegen Esingen Eagles",
            "body": sampleText,
            "image": SiegEsingen,
            "date": "21.05.2022"
        },
        {
            "id": 6,
            "title": "U18 Vize-Pokalsieger",
            "body": sampleText,
            "image": U18Vize,
            "date": "21.05.2022"
        },
    ]




export const Posts = (props) => {

    const backend_server = "https://morning-ridge-83239.herokuapp.com/api/"
    const [postData,setPostData] = useState(initialPosts)

    //TODO: Add later
//  useEffect(() => {
//         fetch(`${backend_server}`,{
//             method: "GET",
//         })
//         .then((response) => response.json())
//         .then((data) => {
//             setPostData(data)
//             console.log(data)
//         })
//     },[])

    return(
<div className={"w-full mb-16"}>

<div class="w-full bg-white ">
<div class="header flex items-end justify-between ">
<div class="title">
      <h1 className="text-4xl font-bold text-gray-800 my-12 mx-4" >Aktuelles</h1>
</div>
</div>
<div className={props.home ? "grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-12" 
: "grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-12"}>
{/* 
    {initialPosts.map((blog) => (
        <Link to={`/news/${blog.id}`}>
        <PostPreview title={blog.title} text={blog.text} image={blog.image_path} />
        </Link>
    ))} */}
    {postData.map((blog) => (
        <Link to={`/news/${blog.id}`}>
        <PostPreview title={blog.title} text={blog.text} image={blog.image} />
        </Link>
    ))}
</div>
</div>
</div>

    );
}

