import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Home } from './pages/home';
import { News } from './pages/news';
import { Probetraining } from './pages/probetraining';
import { Trainingszeiten } from './pages/trainingszeiten';
import { Verein} from './pages/verein';
import { U10 } from './pages/u10';
import {U12} from "./pages/u12"
import {U14} from "./pages/u14"
import {U16} from "./pages/u16"
import {U18} from "./pages/u18"
import {Herren} from "./pages/herren"
import {U35} from "./pages/u35"
import { Post } from './pages/post';
import { Fanshop } from './pages/fanshop';
import Bockholdt from './images/bockholdt.png';
import Hela from './images/sponsor2.png';
import Diwium from './images/sponsor4.png';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
    <App/>
    <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path="/news" element={<News/>} />
      <Route path="/probetraining" element={<Probetraining/>} />
      <Route path="/trainingszeiten" element={<Trainingszeiten/>} />
      <Route path="/verein" element={<Verein/>} />
      <Route path="/u10" element={<U10/>} />
      <Route path="/u12" element={<U12/>} />
      <Route path="/u14" element={<U14/>} />
      <Route path="/u16" element={<U16/>} />
      <Route path="/u18" element={<U18/>} />
      <Route path="/herren" element={<Herren/>} />
      <Route path="/u35" element={<U35/>} />
      <Route path="/fanshop" element={<Fanshop/>} />
      <Route path="/news/:id" element={<Post/>} />
    </Routes>

    </BrowserRouter>
    <footer class="footer p-10 bg-base-300 text-base-content">
  <div>
    <span class="footer-title">Allgemeines</span> 
    <a class="link link-hover" href="/verein">Verein</a> 
    <a class="link link-hover">Impressum</a> 
   {/*  <div className="carousel w-1/2">
  <div id="slide1" className="carousel-item relative w-full">
    <img src={Bockholdt} className="w-full" />
    <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
      <a href="#slide4" className="btn btn-circle">❮</a> 
      <a href="#slide2" className="btn btn-circle">❯</a>
    </div>
  </div> 
  <div id="slide2" className="carousel-item relative w-full">
    <img src={Diwium} className="w-full" />
    <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
      <a href="#slide1" className="btn btn-circle">❮</a> 
      <a href="#slide3" className="btn btn-circle">❯</a>
    </div>
  </div> 
  <div id="slide3" className="carousel-item relative w-full">
    <img src={Hela} className="w-full" />
    <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
      <a href="#slide2" className="btn btn-circle">❮</a> 
      <a href="#slide4" className="btn btn-circle">❯</a>
    </div> 
  </div>  
</div>*/}
  </div> 
    {/* <div>
    <span class="footer-title">Social</span> 
    <div class="grid grid-flow-col gap-4">
      <a><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="fill-current"><path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"></path></svg></a>
      <a><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="fill-current"><path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"></path></svg></a>
      <a><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="fill-current"><path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"></path></svg></a>
    </div> */}
  {/* </div> */}
</footer>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
