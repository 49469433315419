import Navbar from './components/navbar';
import banner from "./images/panthers_banner5.png";


function App() {
  return (
    <div className="bg-white">
      <Navbar/>
      <img className={"w-full -mt-8 xl:-mt-24 "} src={banner}/>
       </div>

  );
}

export default App;
