import { data } from "autoprefixer";
import React, { useEffect, useState } from "react";


export const StatsTable = (props) => {
    const [tableData, setTableData] = useState([])
//    const backend_server = "https://panthers-backend.herokuapp.com/api/"
    const backend_server = "http://194.195.241.7:80/"

    useEffect(() => {
        fetch(`${backend_server}${props.team}`,{
            method: "GET",
        })
        .then((response) => response.json())
        .then((data) => {
            setTableData(data)
        })
    },[])

    return (
        <div>
            <div className="overflow-x-auto ">
  <table className="table table-zebra w-full ">
    <thead>
      <tr>
        <th>Platz</th>
        <th>Name</th>
        <th>Spiele</th>
        <th>W/L</th>
        <th>Punkte</th>
        <th>Körbe</th>
        <th>Differenz</th>
      </tr>
    </thead>
    <tbody>
        {tableData.map(row => (
<tr>
        <th>{row["Platz"]}</th>
        <td className="text-sm">{row["Name"]}</td>
        <td className="text-sm">{row["Spiele"]}</td>
        <td className="text-sm">{row["W/L"]}</td>
        <td className="text-sm">{row["Punkte"]}</td>
        <td className="text-sm">{row["Körbe"]}</td>
        <td className="text-sm">{row["Differenz"]}</td>
      </tr>

        )) }
    </tbody>
  </table>
</div>
                   </div>
    )
}