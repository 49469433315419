import PantherHoodies from "../images/panther_hoodies.png"
import PantherHoodiesKlein from "../images/panther_hoodie_klein.png"
import PantherShirtLogoGross from "../images/panthers_shirt_logo_groß.png"
import PantherShirtLogoKlein  from "../images/panthers_shirt_logo_klein.png"
import PantherShirtSchrift  from "../images/panthers_shirt_schrift.png"
import Turnbeutel from "../images/turnbeutel.png"
import Poloshirt from "../images/polo_shirt.png"
import Beanie from "../images/beanie.png"
import PNTHRS from "../images/shirt_pnthrs.png"
import ZipHoodie from "../images/zip_hoodie.png"  
import { HeadText } from "../components/headText"

const Product = (props) => {

    return (
      <a href={props.href} class="group">
        <div class="w-3/4 aspect-w-1 aspect-h-1 rounded-lg overflow-hidden bg-black xl:aspect-w-7 xl:aspect-h-8 mx-4">
          <img src={props.src} alt="Olive drab green insulated bottle with flared screw lid and flat top." class="w-full h-full object-center object-cover group-hover:opacity-50"/>
        </div>
        <h3 class="mt-4 text-sm text-gray-700">{props.title}</h3>
        <p class="mt-1 text-lg font-medium text-gray-900">{props.price}</p>
      </a>
    )
}

export const Fanshop = () => {

    return (
        <div>
          <div className="grid place-items-center">
          <HeadText text="Panthers Fanshop"/>
          </div>
            <div class="bg-gray-50 m-16 rounded-lg">
  <div class="max-w-2xl mx-auto py-4 px-4 sm:py-4 sm:px-6 lg:max-w-7xl lg:px-8">

    <div class="grid grid-cols-1 gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-3 2xl:grid-cols-4 xl:gap-x-8">
      <Product title="Hoodie mit großem Logo" src={PantherHoodies} price="25,99€ – 28,49€" href="https://shop.sport-basti.de/produkt/panthers-fan-hoodie-mit-grossem-logo/"/>
      <Product title="Hoodie mit kleinem Logo" src={PantherHoodiesKlein} price="25,99€ – 28,49€" href="https://shop.sport-basti.de/produkt/panthers-fan-hoodie-mit-kleinem-logo/"/>
      <Product title="T-Shirt mit großem Logo" src={PantherShirtLogoGross} price="16,99€ – 19,49€" href="https://shop.sport-basti.de/produkt/panthers-fan-t-shirt-mit-grossem-logo/"/>
      <Product title="T-Shirt mit kleinem Logo" src={PantherShirtLogoKlein} price="16,99€ – 19,49€" href="https://shop.sport-basti.de/produkt/panthers-fan-t-shirt-mit-kleinem-logo/"/>
      <Product title="Panthers Fan T-Shirt mit Logo und Schriftzug" src={PantherShirtSchrift} price="19,99€ – 22,49€" href="https://shop.sport-basti.de/produkt/panthers-fan-t-shirt-mit-logo-und-schriftzug/"/>
      <Product title="Panthers Fan T-Shirt mit PNTHRS" src={PNTHRS} price="19,99€ – 21,49€" href="https://shop.sport-basti.de/produkt/panthers-fan-t-shirt-mit-pnthrs/"/>
      <Product title="Panthers Fan Zoodie mit kleinem Logo" src={ZipHoodie} price="27,99€ – 30,49€" href="https://shop.sport-basti.de/produkt/panthers-fan-zoodie-mit-kleinem-logo/"/>
      <Product title="Panthers Beanie Mütze inkl. Logo" src={Beanie} price="12,99€" href="https://shop.sport-basti.de/produkt/panthers-beanie-muetze-inkl-logo/"/>
      <Product title="Panthers Fan Polo-Shirt mit kleinem Logo" src={Poloshirt} price="19,99€" href="https://shop.sport-basti.de/produkt/panthers-fan-polo-shirt-mit-kleinem-logo/"/>
      <Product title="Panthers Turnbeutel mit Logo" src={Turnbeutel} price="12,99€" href="https://shop.sport-basti.de/produkt/panthers-turnbeutel-mit-logo/"/>
      
          </div>
  </div>
</div>
        </div>
    )
}