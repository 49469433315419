import { useState, useEffect } from "react";
import { Header } from "./header";
import { backend_server } from "../constants.js";

const ConfirmationWidget = () => {
  return (
    <div>
<label for="my-modal-3" class="btn modal-button">open modal</label>
<input type="checkbox" id="my-modal-3" class="modal-toggle" />
<div class="modal">
  <div class="modal-box relative">
    <label for="my-modal-3" class="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
    <h3 class="text-lg font-bold">Congratulations random Interner user!</h3>
    <p class="py-4">You've been selected for a chance to get one year of subscription to use Wikipedia for free!</p>
  </div>
</div>
    </div>

  )
}

const showConfirmationWidget = () => {
  return (
<div className="alert alert-success shadow-lg mt-4">
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
    <span>Danke für deine Anfrage. Du erhältst so schnell wie möglich eine Rückmeldung.</span>
  </div>
</div>
  )
}

export const ProbetrainingWidget = () => {
  const [jahrgang, setJahrgang] = useState("");
  const [firstName, setFirstname] = useState("");
  const [lastName, setLastname] = useState("");
  const [message, setMessage] = useState("");
  const [birthYear, setBirthYear] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false)

  const currentYear = new Date().getFullYear();

  const yearDifference = () => {
      return currentYear - birthYear;
  }

  const send_mail = (firstName, lastName, year, message) => {
    
    const payload = {
      subject:"Probetraining",
      firstname: firstName,
      lastname: lastName,
      year: birthYear,
      message: message,
    }

    fetch(
      //"http://127.0.0.1:8001/api/send-mail/"
      `${backend_server}send-mail/`
    , {method:"POST",
    headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    },
    body:JSON.stringify(payload)})
    .then(res => res.json)
    .then(data => 
      {
      return data
    })
  
  }

  const checkTeam = () => {

    if (yearDifference() < 10  ){
        setJahrgang("U10")
    } else if(yearDifference() === 10 || yearDifference() === 11) {
        setJahrgang("U12")
    } else if(yearDifference() === 12 || yearDifference() === 13) {
        setJahrgang("U14")
    } else if(yearDifference() === 14 || yearDifference() === 15) {
        setJahrgang("U16")
    } else if(yearDifference() === 16 || yearDifference() === 17) {
        setJahrgang("U18")
    } else if(yearDifference() >= 18 && yearDifference() <= 35) {
        setJahrgang("Herren")
    } else if(yearDifference() > 35 && yearDifference() < 99) {
        setJahrgang("Ü35")
    } else {
        setJahrgang("Jahrgang wird berechnet ..")
    }

  };

  useEffect(() => {
    checkTeam();
  }, [birthYear]);

  return (
    <div className="probetraining  overflow-hidden rounded-lg  w-full ">
      <p className="flex justify-center items-center mb-8 text-3xl font-bold">Probetraining</p>
      <div className="pb-2">
    <p className="text-xl ">Du hast Lust im Team Basketball zu spielen? Dann melde dich hier unverbindlich für ein Probetraining
        bei uns an.
      </p>
      <form className="px-8">
        <div class=" relative ">
          <input
            type="text"
            id="name-with-label"
            class=" my-2 rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
            name="firstname"
            placeholder="Vorname"
            onChange={(e => {setFirstname(e.target.value)})}
          />
        </div>
        <div class=" relative ">
          <input
            type="text"
            id="name-with-label"
            class="  mb-2 rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
            name="lastname"
            placeholder="Nachname"
            onChange={(e => {
              setLastname(e.target.value)
            })}
          />
        </div>
        <div class=" relative ">
          <input
            type="text"
            id="name-with-label"
            class=" mb-4 rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
            onChange={(e) => {
              setBirthYear(parseInt( e.target.value));
            }}
            name="birthyear"
            placeholder="Geburtsjahr"
          />
        </div>
        <div class=" relative ">
          <textarea
            type="text"
            id="name-with-label"
            class=" mb-4 rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
            onChange={(e) => {
              setMessage( e.target.value);
            }}
            name="birthyear"
            placeholder="Nachricht eingeben.. "
          />
        </div>
        
      </form>
      <div className="grid place-items-center">
      <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"  
      onClick={() =>{


              send_mail(firstName, lastName, birthYear, message)
              setFirstname("")
              setLastname("")
              setBirthYear("")
              setMessage("")
              setShowConfirmation(true)
      } 
      }>Anfrage abschicken</button>
      {showConfirmation ? showConfirmationWidget() : null}
      </div>
      </div>
      </div>
  );
};
