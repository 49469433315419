import banner from "../images/panthers_banner5.png";
import { Posts } from '../components/posts';
import { SideSection } from '../components/sidesection';
import u35photo from "../images/u35-photo.jpg"
import { Probetraining } from "../components/probetraining";
import { Team } from "./team";

export const U35 = () => {
  const beschreibung1 = "Für Männer und Frauen ab 35."
  const beschreibung2 = "Wenn du Lust hast im Team Basketball zu spielen und Spaß zu haben, dann komme gerne vorbei."
    return (
      <div>
        <Team
        teamName="Ü35 männlich/weiblich"
        photo={u35photo}
        description1={beschreibung1}
        description2={beschreibung2}
        tableTeam="Ü35 m/w"
        tableAge="Ab 35 Jahren"
        tableTime="Mi: 20:00 - 21:30"
        tableLocation="Heinrich-Vogler-Halle"
        tableTrainer="Jörg Söhl"
        />
      </div>

      )
}