import { Header } from "../components/header";

const SmallHeader = (props) => {
  return <h1 className="font-semibold text-gray-800 text-xl">{props.text}</h1>;
};

export const Verein = () => {
  return (
    <div>
      <Header text="Verein" />
      <div className="ml-8">
          <div className="mb-8">
              <SmallHeader text="Zahlen und Fakten"/>
              <p>Die Abteilung wächst stetig und besteht derzeit aus rund 100 Mitgliedern. 
                  <br/>
                  Im Jahr 2022/23 nehmen drei Jugendmannschaften sowie die Herren-Mannschaft am Spielbetrieb teil.
                  <br/>
Das Training und der Spielbetrieb finden aktuell fast ausschließlich in der Heinrich-Vogler-Halle der Theodor-Mommsen-Schule statt. 
<br/>
Es bestehen aktuell Trainingsgruppen für Mädchen und Jungen in den Altersklassen U10, U12, U14, U16 und U18. 
<br/>
Eine Teilnahme am Training ist ab 8 Jahre möglich. Es besteht jederzeit die Möglichkeit, ein Probetraining zu vereinbaren. 
<br/>
Bei Interesse gerne an den Abteilungsleiter wenden.</p>
          </div>
    <div className="sm:flex items-start">
        <div className="mt-8 sm:mt-0">
    <SmallHeader text="Geschäftsstelle" />
        <p>
          VfL Oldesloe von 1862 e.V.
          <br />
          Geschäftsstelle
          <br />
          Am Bürgerpark 4
          <br />
          23843 Bad Oldesloe
          <br />
          Telefon: 04531 81656
          <br />
          Fax: 04531 880292
          <br />
          Email: geschaeftsstelle@vfl-oldesloe.de
        </p>
        </div>
        <div className="mt-8 sm:mt-0">
        <SmallHeader text="Geschäftszeiten" />
        <p>
          Mittwoch 09:30 - 12:00 Uhr
          <br />
          Donnerstag 16:30 - 19:00 Uhr
        </p>

        </div>
    </div>
        <div className="mt-8 sm:mt-4">
        <SmallHeader text="Vorstand" />
        <h1>Abteilungsleitung</h1>
        <p>Jörg Söhl</p>
        <h1>stellv. Abteilungsleitung</h1>
        <p>Martin Wegener</p>
        </div>
        <div className="mt-8 sm:mt-4">
        <SmallHeader text="Beitritt" />
        <p>
        Den Aufnahmeantrag erhalten Sie bei uns in der Geschäftsstelle und als <a href="https://www.vfl-oldesloe.de/wp-content/uploads/2021/12/Aufnahmeantrag-2022.pdf" className="text-blue-700 font-semibold"> Download. </a>
        <br/>
        <a href="https://www.vfl-oldesloe.de/mitgliedschaft/" className="text-blue-700 font-semibold">Mehr dazu hier</a>
        </p>
        </div>
        <div className="mt-8 sm:mt-4 mb-12">
        <SmallHeader text="Kontakt" />
        <p>E-Mail: basketball@vfl-oldesloe.de</p>
        </div>
      </div>
    </div>
  );
};
