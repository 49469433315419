import { Posts } from "../components/posts"

export const News= () => {
    return(
        <div>
      <Posts/>
      <div className="grid place-items-center h-40 ">
      <div class="btn-group">
  <button class="btn btn-sm btn-active">1</button>
</div>

      </div>
        </div>
    )
}