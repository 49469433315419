import banner from "../images/panthers_banner5.png";
import { Posts } from '../components/posts';
import { SideSection } from '../components/sidesection';
import u10photo from "../images/u10-photo.png"
import { Probetraining } from "../components/probetraining";
import { Team } from "./team";

export const U10 = () => {
  const beschreibung1 = "Für Mädchen und Jungs aus dem Jahrgang 2013 und 2014."
  const beschreibung2 = "Wenn du Lust hast im Team Basketball zu spielen und Spaß zu haben, dann komme gerne vorbei. Während jeder Saison wird zusätzlich zu den Trainings auch an Turnieren teilgenommen."
    return (
      <div>
        <Team
        teamName="U10 männlich/weiblich"
        photo={u10photo}
        description1={beschreibung1}
        description2={beschreibung2}
        tableTeam="U10 m/w"
        tableAge="8 - 9 Jahre"
        tableTime="Fr: 16:30 - 18:00"
        tableLocation="Heinrich-Vogler-Halle"
        tableTrainer="Corina Pochanke"
        />
      </div>

      )
}